import { computed, ref } from 'vue';
import result from 'lodash/result';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import Api from '@/vue/api/backoffice';

import { DELAY } from '@emobg/web-utils';
import { SOURCES } from '@/composable/Algolia/sources.const';
import { backOffice } from '@emobg/web-api-client';

import cfg from '@/config';
import { useTranslations } from '@/composable/App/Translations';
import { userData as userDataGetter } from '@/stores/User/UserData/UserDataMapper';

const { $t } = useTranslations();
const { algoliaAppId, algoliaPrefix } = cfg.data;

export const useAlgolia = (version = 'v3') => {
  const REFRESH_KEY_INTERVAL_TIME = 480000; // 8 minutes for refreshing the algolia api key
  const userData = computed(userDataGetter);
  const apiKey = ref('');
  const algoliaConfig = {
    appId: algoliaAppId,
    prefix: algoliaPrefix,
  };

  let getApiKey = async (index, filters, validations = []) => {
    const defaultParams = {
      index: null,
      filters: null,
      validations: {},
      user_uuid: '',
      source: SOURCES.webapp,
    };

    const params = {
      index: `${algoliaConfig.prefix}${index}`,
      filters,
      validations,
      user_uuid: get(userData, 'value.uuid'),
    };

    const parameters = Object.assign(defaultParams, params);

    const responseRefreshKey = await backOffice.app.getAlgoliaSearchKey(parameters);

    apiKey.value = responseRefreshKey.apiKey;
  };

  if (version === 'v2') {
    getApiKey = async (index, filters, validations = []) => {
      const responseRefreshKey = await Api.app.getAlgoliaSecuredApiKey({
        index: `${algoliaConfig.prefix}${index}`,
        filters,
        validations,
        user_uuid: get(userData, 'value.uuid'),
      });

      apiKey.value = responseRefreshKey.data;
    };
  }

  const initAlgolia = async (index, filters, validations = []) => {
    await getApiKey(index, filters, validations);
    setInterval(getApiKey, REFRESH_KEY_INTERVAL_TIME, index, filters, validations);
  };

  const getTotalResults = listComponentRef => result(listComponentRef, 'algoliaResults.totalResults');

  const getFacetValues = (listComponentRef, attributeName) => {
    const algoliaResults = result(listComponentRef, 'algoliaResults');
    return algoliaResults && algoliaResults.getFacetValues(attributeName);
  };

  const refreshAlgoliaTable = (listComponentRef, delay = DELAY.medium, callback = () => ({})) => {
    setTimeout(() => {
      result(listComponentRef, 'algoliaResults.clearCache');
      result(listComponentRef, 'algoliaResults.refresh');
      result(listComponentRef, 'algoliaResults._helper.search');
      callback();
    }, delay);
  };

  const getAlgoliaLabels = tableLabels => reduce(tableLabels, (labels, value, key) => ({
    ...labels,
    [key]: $t(value),
  }), {});

  return {
    apiKey,
    algoliaConfig,
    initAlgolia,
    getTotalResults,
    getFacetValues,
    refreshAlgoliaTable,
    getApiKey,
    getAlgoliaLabels,
  };
};
